<template>
    <div>
        <el-drawer :with-header="false" size="40%" :visible.sync="dialogDetails" direction="rtl"
            :before-close="handleClose">
            <div class="gl_drawer">
                <!-- 顶部头像 -->
                <div class="gl_drawer_logo flex_center_x">
                    <div class="logo">
                        <img :src="detailsData.avatarUrl" alt="">
                    </div>
                    <div class="title">{{ detailsData.nickName }}</div>
                </div>

                <div class="gl_balance_view flex_center_x">
                    <div class="ul flex_column" style="width: 140px;">
                        <div class="li flex_end">
                            <div class="title">余额</div>
                        </div>
                        <div class="money">{{ detailsData.balance || '0.00' }}元</div>
                    </div>
                </div>
                <!-- tabs -->
                <div class="gl_tabs">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane name="first" label="用户资料">
                            <div class="gl_tabs_view">
                                <!-- 顶部标题 -->
                                <div class="gl_title flex_center_x" style="margin-bottom: 2px;">
                                    <div class="radius"></div>
                                    <div class="title">基本信息</div>
                                </div>
                                <!-- 内容 -->
                                <div class="gl_info flex_center_x">
                                    <div class=" gl_info_ul flex_center_x">
                                        <div class="title">用户名：</div>
                                        <div class="text">{{ detailsData.loginName }}</div>
                                    </div>
                                </div>
                                <div class="gl_info flex_center_x">
                                    <div class=" gl_info_ul flex_center_x">
                                        <div class="title">用户编号：</div>
                                        <div class="text">{{ detailsData.id }}</div>
                                    </div>
                                    <div class=" gl_info_ul flex_center_x">
                                        <div class="title">手机号码：</div>
                                        <div class="text">{{ detailsData.phone }}</div>
                                    </div>
                                </div>
                                <!-- 分割线 -->
                                <div class="gl_solid">
                                    <el-divider></el-divider>
                                </div>
                                <!-- 顶部标题 -->
                                <div class="gl_title flex_center_x" style="margin-bottom: 2px;">
                                    <div class="radius"></div>
                                    <div class="title">用户概况</div>
                                </div>
                                <!-- 内容 -->
                                <div class="gl_info flex_center_x">
                                    <div class=" gl_info_ul flex_center_x">
                                        <div class="title">用户状态：</div>
                                        <div class="text">{{ detailsData.state == 1 ? '开启' : '关闭' }}</div>
                                    </div>
                                </div>
                                <div class="gl_info flex_center_x">
                                    <div class=" gl_info_ul flex_center_x">
                                        <div class="title">注册时间：</div>
                                        <div class="text">{{ detailsData.createTime }}</div>
                                    </div>
                                    <div class=" gl_info_ul flex_center_x">
                                        <div class="title">登录时间：</div>
                                        <div class="text">{{ detailsData.lastLoginTime || "近期暂未登陆" }}</div>
                                    </div>
                                </div>

                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="second" label="用户团队">
                            <div class="gl_tabs_view_table">
                                <el-table :data="secondList" style="width: 100%" :cell-style="{ 'text-align': 'center' }"
                                    :header-cell-style="{ background: '#B5DFFF', color: '#333333', 'text-align': 'center' }">
                                    <el-table-column prop="name" fixed="left" label="团队ID" width="70"></el-table-column>
                                    <el-table-column prop="name" fixed="left" label="团队名称"></el-table-column>
                                    <el-table-column prop="name" label="团队内备注" width="120"></el-table-column>
                                    <el-table-column prop="name" label="角色" width="70"></el-table-column>
                                    <el-table-column prop="name" label="创建时间" width="150"></el-table-column>
                                    <el-table-column prop="name" label="最后操作时间" width="150"></el-table-column>
                                </el-table>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="third" label="资金明细">
                            <div class="gl_tabs_view_table">
                                <el-table :data="thirdList" style="width: 100%" :cell-style="{ 'text-align': 'center' }"
                                    :header-cell-style="{ background: '#B5DFFF', color: '#333333', 'text-align': 'center' }">
                                    <el-table-column prop="name" fixed="left" label="动作" width="70"></el-table-column>
                                    <el-table-column prop="name" fixed="left" label="资金账户"></el-table-column>
                                    <el-table-column prop="name" label="变动金额" width="80"></el-table-column>
                                    <el-table-column prop="name" label="余额" width="70"></el-table-column>
                                    <el-table-column prop="name" label="操作用户" width="100"></el-table-column>
                                    <el-table-column prop="name" label="创建时间" width="150"></el-table-column>
                                    <el-table-column prop="name" label="备注" width="150"></el-table-column>
                                </el-table>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
export default {
    props: ["dialogDetails"],
    data() {
        return {
            detailsId: '',
            activeName: 'first',
            detailsData: {}, //1 部分
            secondList: [], //2 部分
            thirdList: [],//3 部分
        }
    },
    created() {

    },
    mouted() {

    },
    methods: {
        handleClose() {
            this.$emit('detailsClose', false);
        },
        handleClick(tab, event) {
            console.log(this.activeName)
            if (this.activeName == 'second' && this.secondList.length == 0) {
                this.getSecondData();
            }
            if (this.activeName == 'third' && this.thirdList.length == 0) {
                this.getThirdData();
            }
        },
        // 父组件 调用执行
        async callMethod(msg) {
            this.detailsId = msg;
            this.activeName = 'first';
            this.secondList = [];
            this.thirdList = [];
            this.information(msg);
        },
        // 获取  用户资料
        async information(msg) {
            const { data: res } = await this.$http({
                url: "/user/getDetail",
                method: "post",
                data: { id: msg }
            });
            if (res.resultStates != 0) return this.$message.error(res.message)
            this.detailsData = res.content;
        },
        // 获取  用户团队
        async getSecondData() {
            const { data: res } = await this.$http({
                url: "/user/getDetail",
                method: "post",
                data: { id: msg }
            });
            if (res.resultStates != 0) return this.$message.error(res.message)
            this.detailsData = res.content;
        },
        // 获取  资金明细
        async getThirdData() {
            const { data: res } = await this.$http({
                url: "/user/getDetail",
                method: "post",
                data: { id: msg }
            });
            if (res.resultStates != 0) return this.$message.error(res.message)
            this.detailsData = res.content;
        },
    },
}
</script>
<style scoped>
.gl_drawer {
    box-sizing: border-box;
    padding-top: 60px;
}

.gl_drawer_logo {
    width: 100%;
    height: 52px;
    box-sizing: border-box;
    padding: 0 20px;
}

.gl_drawer_logo>.logo {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: #f1f1f1;
}

.gl_drawer_logo>.logo>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.gl_drawer_logo>.title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    margin-left: 20px;
}

.gl_tabs {
    width: 100%;
    height: 100%;
    margin-top: 20px;
}


.gl_tabs_view {
    box-sizing: border-box;
    /* padding-top: 14px; */
}

.gl_tabs_view_table {
    box-sizing: border-box;
    padding: 0 20px;
}

.gl_title {
    width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
}

.gl_title>.radius {
    width: 5px;
    height: 5px;
    background: rgba(24, 131, 227);
    border-radius: 50%;
    opacity: 1;
}

.gl_title>.title {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-left: 6px;
}

.gl_info {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin-top: 10px;
}

.gl_info_ul {
    width: 240px;
    height: 22px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
}

.gl_info_ul>.title {
    width: 80px;
    text-align: right;
}

.gl_solid {
    box-sizing: border-box;
    padding: 0 14px;
}

/* 资金 */
.gl_balance_view {
    width: 100%;
    height: 54px;
    box-sizing: border-box;
    padding: 0 20px;
    margin-top: 20px;
}

.gl_balance_view>.ul {
    width: 220px;
    height: 100%;
}

.gl_balance_view>.ul>.money {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    opacity: 1;
}

.gl_balance_view>.ul>.li>.title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    opacity: 1;
    margin-right: 4px;
}


.flex_center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex_center_x {
    display: flex;
    align-items: center;
}

.flex_column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>

<style>
.gl_tabs .el-tabs--border-card {
    border: none;
    box-shadow: none;


}

.gl_tabs .el-tabs__nav-scroll {
    background: rgba(244, 247, 254);
    height: 48px;
}

.gl_tabs .el-tabs__item {
    width: 108px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    padding: 0;
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 500;
    opacity: 1;
}

.gl_tabs .el-tabs__item.is-active {
    background: #fff;
}

.gl_tabs .el-tabs__active-bar {
    width: 50px !important;
    left: 30px;
}

.gl_tabs .el-tabs__nav-wrap::after {
    background-color: #fff
}
</style>