<template>
    <div>
        <el-dialog title="添加团队" :visible.sync="dialogAddTeam" width="40%" :before-close="handleClose"
            custom-class="gl_dialog_wrapper">
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="团队名称" prop="name">
                    <el-input placeholder="请输入" v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="成员数量" prop="childNum">
                    <el-input placeholder="请输入" v-model="ruleForm.childNum"></el-input>
                </el-form-item>
                <el-form-item label="分组">
                    <el-select class="input-with" v-model="ruleForm.groupName" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="标签">
                    <el-cascader v-model="arrLabels" class="input-with" :options="optionsCascader" :props="props"
                        clearable></el-cascader>
                </el-form-item>

                <el-form-item label="备注">
                    <el-input type="textarea" v-model="ruleForm.remark"></el-input>
                </el-form-item>

                <el-form-item label="状态">
                    <el-radio-group v-model="ruleForm.status">
                        <el-radio :label="1">开启</el-radio>
                        <el-radio :label="2">关闭</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item>
                    <div class="form_item_button">
                        <el-button @click="resetForm('ruleForm')">重 置</el-button>
                        <el-button type="primary" @click="submitForm('ruleForm')">提 交</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    //子组件通过props接收父组件的传值
    props: ["dialogAddTeam"],
    data() {
        var validatePhone = (rule, value, callback) => {
            var reg = /^\d+$/;
            if (value === '') {
                callback(new Error('请输入成员数量'));
            } else if (!reg.test(value)) {
                callback(new Error('成员数量仅支持整数哦'));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                name: '',
                childNum: '',
                groupName: '',
                labels: [],
                remark: '',
                status: 1,
            },
            arrLabels: [],
            rules: {
                name: [
                    { required: true, message: '请输入团队名称', trigger: 'blur' }
                ],
                childNum: [
                    { required: true, validator: validatePhone, trigger: 'blur' }
                ],
            },
            options: [{
                value: '金融机构',
                label: '金融机构'
            }, {
                value: '主机厂',
                label: '主机厂'
            }, {
                value: '物流企业',
                label: '物流企业'
            }, {
                value: '政府机构',
                label: '政府机构'
            }, {
                value: '经销商',
                label: '经销商'
            }],
            props: { multiple: true },
            optionsCascader: [{
                value: '食品',
                label: '食品',
                children: [{
                    value: '包子',
                    label: '包子'
                }, {
                    value: '大米',
                    label: '大米',
                }, {
                    value: '韭菜',
                    label: '韭菜',
                }]
            }, {
                value: '木材',
                label: '木材',
                children: [{
                    value: '桌子',
                    label: '桌子'
                }, {
                    value: '椅子',
                    label: '椅子',
                }, {
                    value: '板凳',
                    label: '板凳',
                }]
            }],
            detailsId: "",
        }
    },
    created() {

    },
    mouted() {

    },
    methods: {
        // 添加
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let labels = [];
                    for (var a = 0; a < this.arrLabels.length; a++) {
                        labels.push(this.arrLabels[a][1])
                    }
                    this.ruleForm.labels = labels.join();
                    this.ruleForm.id = this.detailsId.id;
                    console.log(this.ruleForm)
                    var { data: res } = await this.$http({
                        url: "/member/add",
                        method: "post",
                        data: this.ruleForm
                    });
                    if (res.resultStates != 0) return this.$message.error(res.message)
                    this.$message.success("添加成功")
                    this.$emit('submitForm', true);
                    this.ruleForm = {
                        name: '',
                        childNum: '',
                        groupName: '',
                        labels: [],
                        remark: '',
                        status: 1,
                    }
                    this.arrLabels = [];
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 重置
        resetForm() {


        },
        // 关闭弹窗
        handleClose() {
            this.$emit('addTeamClose', false);
        },
        // 执行弹窗
        callMethod(msg) {
            console.log(msg)
            this.detailsId = msg;
            // 获取分组接口
        },
    },
}
</script>
<style scoped>
.avatar-uploader {
    width: 104px;
    height: 108px;
    position: relative;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 104px;
    height: 108px;
    line-height: 104px;
    text-align: center;
    border: 1px solid #CCCCCC;
    overflow: hidden;
}

.avatar {
    width: 104px;
    height: 108px;
    display: block;
}

.avatar_delete {
    position: absolute;
    top: -10px;
    right: 0;
}

.input-with {
    width: 100%;
    font-family: PingFang SC;
    font-weight: 400;
    opacity: 1;
}

.form_item_button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/*  */
</style>