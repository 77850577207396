<template>
    <div>
        <el-dialog :title="dialogValue == 'add' ? '添加用户' : '修改用户'" :visible.sync="dialogVisible" width="40%"
            :before-close="handleClose" custom-class="gl_dialog_wrapper">
            <el-form :model="dialogData" status-icon :rules="rules" ref="dialogData" label-width="100px"
                class="demo-dialogData">
                <el-form-item label="用户名" prop="loginName">
                    <el-input placeholder="请输入用户名" v-model="dialogData.loginName"></el-input>
                </el-form-item>

                <el-form-item label="手机号码" prop="phone">
                    <el-input placeholder="请输入手机号码" v-model="dialogData.phone"></el-input>
                </el-form-item>
                <el-form-item label="用户昵称" prop="nickName">
                    <el-input placeholder="请输入用户昵称" v-model="dialogData.nickName"></el-input>
                </el-form-item>
                <el-form-item label="用户头像" prop="avatarUrl">
                    <el-upload :disabled="dialogData.avatarUrl ? true : false" class="avatar-uploader"
                        action="https://apisgu.chinaucv.com/staffPlatform/upload/uploadImage" :show-file-list="false"
                        :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :headers="uploadHeaders"
                        accept="image/jpg,image/jpeg,image/png" name="image" :data="{ type: 0, vehicleModelId: 0 }"
                        :multiple="false">
                        <!-- <img v-if="dialogData.avatarUrl" :src="dialogData.avatarUrl" class="avatar"> -->
                        <div style="background: #fff;">
                            <el-image class="avatar" :zIndex="9999" v-if="dialogData.avatarUrl" :src="dialogData.avatarUrl"
                                :preview-src-list="[dialogData.avatarUrl]" fit="contain">
                            </el-image>
                        </div>
                        <i v-if="!dialogData.avatarUrl" class="el-icon-plus avatar-uploader-icon"></i>
                        <div class="avatar_delete" v-if="dialogData.avatarUrl" @click.stop="onClickDeleteImage()">
                            <img style="width:14px;height: 14px;" src="../../assets/error.png" alt="">
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="登陆密码" prop="loginPassword">
                    <el-input placeholder="若不修改请留空" type="password" show-password
                        v-model="dialogData.loginPassword"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPass">
                    <el-input placeholder="若不修改请留空" type="password" show-password v-model="dialogData.checkPass"></el-input>
                </el-form-item>

                <el-form-item label="状态">
                    <el-radio-group v-model="dialogData.state">
                        <el-radio :label="1">开启</el-radio>
                        <el-radio :label="2">关闭</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item>
                    <div class="form_item_button">
                        <el-button v-show="dialogValue == 'add'" @click="resetForm('dialogData')">重 置</el-button>
                        <el-button type="primary" @click="submitForm('dialogData')">提 交</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    //子组件通过props接收父组件的传值
    props: ["dialogVisible", "dialogData", "dialogValue"],
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback()
            } else if (value.length < 6) {
                callback(new Error('密码长度不符合,大于6位'));
            }
            callback();
        };
        var validatePass2 = (rule, value, callback) => {
            if (this.dialogData.loginPassword === '' && value === '') {
                callback();
            } else if (value !== this.dialogData.loginPassword) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        var validatePhone = (rule, value, callback) => {
            var reg = /^[1][3,4,5,7,8][0-9]{9}$/;
            if (value === '') {
                callback(new Error('请输入手机号'));
            } else if (!reg.test(value)) {
                callback(new Error('手机号输入有误'));
            } else {
                callback();
            }
        };
        var validateName = (rule, value, callback) => {
            var reg = /^[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]*$/;
            if (value === '') {
                callback(new Error('请输入用户名'));
            } else if (!reg.test(value)) {
                callback(new Error('用户名仅包含字母或数字'));
            } else {
                callback();
            }
        };
        return {
            rules: {
                loginName: [
                    { required: true, validator: validateName, trigger: 'blur' }
                ],
                phone: [
                    { required: true, validator: validatePhone, trigger: 'blur' }
                ],
                nickName: [
                    { required: true, message: '请输入用户昵称', trigger: 'blur' },
                    { min: 1, max: 7, message: '长度在 7 个字符以内', trigger: 'blur' }
                ],
                loginPassword: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'blur' }
                ],
            },
            uploadHeaders: {},
            loading:null,
        }
    },
    created() {
        // 获取token
        let token = sessionStorage.getItem("token");
        this.uploadHeaders = { token };
    },
    mouted() {
        // 查询 添加还是修改
    },
    methods: {
        // 确认按钮
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let obj = JSON.parse(JSON.stringify(this.dialogData))
                    delete obj.checkPass
                    if (this.dialogValue == 'add') {
                        var { data: res } = await this.$http({
                            url: "/user/add",
                            method: "post",
                            data: obj
                        });
                    } else {
                        var { data: res } = await this.$http({
                            url: "/user/update",
                            method: "post",
                            data: obj
                        });
                    }
                    if (res.resultStates != 0) return this.$message.error(res.message)
                    if (this.dialogValue == 'add') {
                        this.$message.success("添加成功")
                    } else {
                        this.$message.success("修改成功")
                    }
                    this.$emit('submitForm', true);
                } else {
                    return false;
                }
            });
        },
        // 关闭弹窗
        handleClose() {
            this.$emit('handleClose', false);
        },
        // 上传照片
        handleAvatarSuccess(file) {
            this.loading.close();
            this.dialogData.avatarUrl = file.content.urls[0]
        },
        beforeAvatarUpload() {
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        // 删除照片
        onClickDeleteImage() {
            this.dialogData.avatarUrl = "";
        },
        // 重制
        resetForm(formName) {
            this.dialogData.loginName = '';
            this.dialogData.nickName = '';
            this.dialogData.phone = '';
            this.dialogData.loginPassword = '';
            this.dialogData.avatarUrl = '';
            this.dialogData.checkPass = '';
            this.dialogData.state = 1;
        }
    },
}
</script>
<style scoped>
.avatar-uploader {
    width: 104px;
    height: 108px;
    position: relative;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 104px;
    height: 108px;
    line-height: 104px;
    text-align: center;
    border: 1px solid #CCCCCC;
    overflow: hidden;
}

.avatar {
    width: 104px;
    height: 108px;
    display: block;
}

.avatar_delete {
    position: absolute;
    top: -10px;
    right: 0;
}

.form_item_button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.gl_dialog_wrapper {
    padding: 0;
}

.el-dialog__wrapper .el-dialog__body {
    padding-bottom: 2px;
}

/*  */
</style>

<style>
.gl_dialog_wrapper .el-dialog__body {
    padding-bottom: 2px;

}
</style>