<template>
    <div id="app">
        <!-- 顶部搜索 -->
        <div class="gl_top_view">
            <div class="gl_top_input">
                <div class="gl_top_fal">
                    <div class="title">昵称</div>
                    <el-input class="input-with" v-model="pages.nickName" placeholder="请输入昵称"></el-input>
                </div>
                <div class="gl_top_fal">
                    <div class="title">手机号</div>
                    <el-input class="input-with" v-model="pages.phone" placeholder="请输入手机号"></el-input>
                </div>
                <div class="gl_top_fal">
                    <div class="title">创建日期</div>
                    <el-date-picker style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="input" type="daterange"
                        align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                        :picker-options="pickerOptions" @change="onClickPicker">
                    </el-date-picker>
                </div>
            </div>
            <div class="gl_top_buttons">
                <div @click="onClickScreen" class="screen">筛选</div>
                <div @click="onClickReset(true)" class="resetting">重置</div>
            </div>
        </div>
        <!-- 内容部分 -->
        <div class="gl_main_view">
            <div class="gl_button" @click="onClickUser('add')">添加用户</div>
            <el-table :data="tableData" style="width: 100%" :cell-style="{ 'text-align': 'center' }"
                :header-cell-style="{ background: '#B5DFFF', color: '#333333', 'text-align': 'center' }">
                <el-table-column prop="id" label="ID" width="80">
                </el-table-column>
                <el-table-column label="头像" width="80">
                    <template slot-scope="scope">
                        <el-image style="width: 50px; height: 50px;border-radius: 50%;" :src="scope.row.avatarUrl"
                            fit="scale-down"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="nickName" label="昵称">
                </el-table-column>
                <el-table-column prop="phone" label="手机号">
                </el-table-column>
                <el-table-column prop="name" label="状态" width="80">
                    <template slot-scope="scope">
                        <div>{{ scope.row.state == 1 ? '开启' : '关闭' }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间">
                </el-table-column>
                <el-table-column prop="createBy" label="创建人" width="80">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button @click="onClickUser('edit', scope.row)" type="text">编辑</el-button>
                        <el-button @click="handleClick(scope.row)" type="text">查看详情</el-button>
                        <el-button @click="handleAddTeam(scope.row)" type="text">添加团队</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="gl_pagination">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="pages.pageNumber" :page-sizes="[10, 20, 30, 50]" :page-size="pages.pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <!--  添加/修改 -->
        <userListEdit @submitForm="submitForm" @handleClose="handleClose" :dialogData="dialogData"
            :dialogVisible="dialogVisible" :dialogValue="dialogValue"></userListEdit>
        <!-- 详情的弹窗 -->
        <userListDetails ref="userListDetails" @detailsClose="detailsClose" :dialogDetails="dialogDetails">
        </userListDetails>
        <!-- 添加团队 -->
        <userListAddTeam @submitForm="submitFormAddTeam" ref="userListAddTeam" @addTeamClose="addTeamClose"
            :dialogAddTeam="dialogAddTeam">
        </userListAddTeam>
    </div>
</template>

<script>
import userListEdit from "@/views/teamManagement/userListEdit.vue"
import userListDetails from "@/views/teamManagement/userListDetails.vue"
import userListAddTeam from "@/views/teamManagement/userListAddTeam.vue"

export default {
    // 添加/修改的
    components: {
        userListEdit,
        userListDetails,
        userListAddTeam,
    },
    data() {
        return {
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 1);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 1);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 1);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            input: [],//时间展示
            tableData: [],
            total: 0,
            // 添加/修改-三个对象
            dialogVisible: false,
            dialogValue: "add",
            dialogData: {},
            // 详情页对象
            dialogDetails: false,
            // 添加团队
            dialogAddTeam: false,
            pages: {
                nickName: "",
                phone: "",
                startTime: "",
                endTime: "",
                pageNumber: 1,
                pageSize: 10,
            },
        }
    },
    created() {
        this.getList(true);
    },
    mouted() {

    },
    methods: {
        // 点击筛选
        onClickScreen() {
            this.pages.pageNumber = 1;
            this.pages.pageSize = 10;
            this.getList(true);
        },
        // 点击重置顶部form
        onClickReset(type) {
            this.pages = {
                nickName: "",
                phone: "",
                startTime: "",
                endTime: "",
                pageNumber: 1,
                pageSize: 10,
            }
            this.input = [];
            this.getList(type);
        },
        // 点击时间选择
        onClickPicker(event) {
            console.log(event)
            if (event == null) {
                this.pages.startTime = '';
                this.pages.endTime = '';
            } else {
                this.pages.startTime = event[0];
                this.pages.endTime = event[1];
            }
        },
        // 分页
        handleSizeChange(val) {
            this.pages.pageSize = val;
            this.getList(true);
        },
        handleCurrentChange(val) {
            this.pages.pageNumber = val;
            this.getList(true);
        },
        // 点击 添加/修改
        onClickUser(value, data = {
            loginName: '',
            nickName: '',
            phone: '',
            loginPassword: '',
            avatarUrl: '',
            checkPass: '',
            state: 1,
        }) {
            let o = JSON.parse(JSON.stringify(data))
            o.loginPassword = '';
            o.checkPass = '';
            this.dialogVisible = true;
            this.dialogValue = value;
            this.dialogData = o;
        },
        // 关闭弹窗 添加/修改
        handleClose(msg) {
            console.log(msg)
            this.dialogVisible = msg;
        },
        // 添加/修改 成功
        submitForm() {
            this.dialogVisible = false;
            this.onClickReset(false);
        },

        // 查看 打开弹窗 -查看详情
        handleClick(data) {
            this.dialogDetails = true;
            this.$refs.userListDetails.callMethod(data.id)
        },
        // 关闭 弹窗-查看详情
        detailsClose(msg) {
            this.dialogDetails = msg;
        },
        // 添加团队
        handleAddTeam(data) {
            console.log(data.isMember)
            if (data.isMember) return this.$message.info("该用户已有团队，无法添加")
            this.dialogAddTeam = true;
            this.$refs.userListAddTeam.callMethod(data)
        },
        // 关闭弹窗
        addTeamClose(msg) {
            this.dialogAddTeam = msg
        },
        // 添加团队 提交成功
        submitFormAddTeam() {
            this.dialogAddTeam = false
            this.onClickReset(false);
        },
        // 获取列表
        async getList(type) {
            const { data: res } = await this.$http({
                url: "user/getList",
                method: "post",
                data: this.pages
            });
            if (res.content == null) {
                this.total = 0
                this.tableData = []
            } else {
                this.total = res.content.count
                this.tableData = res.content.items
            }

            if (type) {
                this.$message.success("查询成功")
            }
        },
    },
}
</script>
<style scoped>
.gl_top_view {
    width: 100%;
    height: 132px;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 0px 10px #BFDFF7;
    opacity: 1;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px 20px;
}

.gl_top_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gl_top_fal {
    /* width: 284px; */
    width: 30%;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gl_top_fal>.title {
    min-width: 64px;
    max-width: 64px;
    margin-right: 10px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    text-align: right;
}

.gl_top_fal .input-with {
    width: 100%;
    font-size: 16px !important;
    font-family: PingFang SC;
    font-weight: 400;
    opacity: 1;
}

.gl_top_buttons {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.gl_top_buttons>div {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 115px;
    height: 34px;
    opacity: 1;
    border-radius: 6px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
}

.gl_top_buttons>.screen {
    background: rgba(0, 131, 227);
    color: #FFFFFF;
    cursor: pointer;
}

.gl_top_buttons>.resetting {
    border: 1px solid #1883E3;
    color: #1883E3;
    margin-left: 20px;
    cursor: pointer;
}

.gl_main_view {
    width: 100%;
    background: rgba(255, 255, 255);
    opacity: 1;
    border-radius: 10px;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 20px;
}

.gl_button {
    width: 100px;
    height: 34px;
    background: rgba(0, 131, 227);
    opacity: 1;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 20px;
    cursor: pointer;
}

.gl_pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
</style>